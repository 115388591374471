import { NavBar } from "../components/NavBar";
import { useEffect, useState } from "react";
import { SectionHeading } from "../components/SectionHeading";
import { Container } from "../components/Container";
import FocusPrograms from "./focusPrograms";
import { VideoTestimonial } from "../components/VideoTestimonial";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Accordion } from "../components/Accordion";
import BlueGlobe from "../images/BlueGlobe.jpg";
import Grad from "../images/grad.jpg";
import { Hero } from "../components/Hero";
import SvvsdLogo from "../images/svvsd-logo.png";
import HsoptionsCover from "../images/hsoptions-cover.jpg";
import Medical from "../images/medical.jpg";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import SiteNavigation from "../components/SiteNavigation";

import { Link } from "react-router-dom";
import { Highlights } from "../components/Highlights";
import { urlFor } from "../utils/imageBuilder";
import { Footer } from "../components/Footer";
import HomeHero from "../components/HomeHero";

const builder = imageUrlBuilder(sanityClient);

export default function Home() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "school"]{
          title,
          slug,
          logo, 
          color,
          mainImage,
          seoDescription,
        }
      `
      )
      .then((data) => {
        setAllPosts(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--bg-color", "#862633");
    document.documentElement.style.setProperty("--bg-color-light", "#86263311");
  }, []);


  const [hashScrolled, setHashScrolled] = useState(false);

  useEffect(() => {
    if (allPostsData && !hashScrolled) {
      setHashScrolled(true);
      if (window.location.hash) {
        const hash = window.location.hash;
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top;

          const offsetPosition = elementPosition;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
            duration: 1000,
          });
        }
      }
    }
  }, [allPostsData]);

  return (
    <>
    <SiteNavigation />
    <HomeHero />
    {/* <SiteNavigation /> */}

      {/* <Hero
        title="Guide to High School Options"
        staticImage={HsoptionsCover}
        staticLogo={SvvsdLogo}
        subtitle="Your next journey begins here."
      /> */}
      <NavBar
        links={[
          { id: "advantage", title: "The St. Vrain Advantage" },
          { id: "district-programs", title: "Districtwide Programs" },
          { id: "focus-programs", title: "Focus Pathways" },
          { id: "schools", title: "High School Options" },
        ]}
      />
      <section id="advantage" className="pt-36 relative">
        <Container size="lg">
          <div className="relative lg:grid grid-cols-2 bg-white pb-24 gap-16">
            <div className="">
              <div className="">
                <div>
                  <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                  <i class="fa-duotone fa-earth-europe h-5 w-5"></i>
                  </div>
                </div>
                <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  The St. Vrain Advantage
                </h2>
                <p className="mt-6 text-lg text-gray-500">
                  This is a very exciting time in our schools as we continue to
                  ensure student success and a strong competitive advantage for
                  a globalized 21st-century economy. St. Vrain Valley Schools’
                  college preparatory and high-quality instructional focus
                  programs – delivered through rigorous courses and engaging
                  activities – ensure that your student will receive a
                  world-class high school education. We are providing current
                  and future generations a strong competitive advantage so that
                  all students can achieve success in a globalized world.
                </p>
                <div className="mt-8 overflow-hidden">
                  <dl className="-mt-8 grid grid-cols-2 gap-4">
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        high-quality high school focus programs
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        12
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        credits to graduate, among the most rigorous in Colorado
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        24.5
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        career and technical education programs at the high
                        school level
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        40+
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        of St. Vrain high school students are engaged in
                        co-curricular activities
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        82%
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        graduation rate
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        91.6%
                      </dd>
                    </div>
                    <div className="flex flex-col pt-8">
                      <dt className="order-2 text-base font-medium text-gray-500">
                        business and industry partners who support student
                        advancement
                      </dt>
                      <dd className="order-1 text-2xl font-bold text-[color:var(--bg-color)] sm:text-3xl sm:tracking-tight">
                        120+
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="w-full h-full mt-24">
              <Accordion title="Rigorous Graduation Requirements">
                <p>
                  St. Vrain’s graduation requirements consist of a three-pronged
                  approach, designed to educate and prepare students to become
                  well-rounded citizens who are prepared for success in the
                  competitive global economy. Rigorous coursework and credit
                  hours are a focus of St. Vrain’s graduation requirements. In
                  order to graduate, students must complete the following three
                  components before receiving a St. Vrain Valley Schools
                  diploma:
                </p>
                <p>
                  Graduates will create and successfully complete an Individual
                  Career and Academic Plan (ICAP). The ICAP will serve as their
                  roadmap to graduation and postsecondary readiness. Graduates
                  will be required to complete a minimum of 24.5 credits:
                </p>
                <ul>
                  <li>English (4)</li>
                  <li>Social Studies (3)</li>
                  <li>Financial Literacy (0.5)</li>
                  <li>Mathematics (3)</li>
                  <li>Science (3)</li>
                  <li>Physical Education (2)</li>
                  <li>Practical Arts (0.5)</li>
                  <li>Fine Arts (0.5)</li>
                  <li>Health (0.5)</li>
                  <li>General Electives (7.5)</li>
                </ul>
                <p>
                  St. Vrain Valley students will also need to complete a
                  Graduation Capstone, which consists of completion of the
                  student’s ICAP and 0.5 credits in financial literacy
                  coursework.
                </p>
                <a href="http://stvra.in/graduationrequirements">
                  Learn more about St. Vrain Graduation Requirements{" "}
                </a>
              </Accordion>
              <Accordion title="Advancing Excellence for All Students">
                <p>
                  St. Vrain provides a rigorous academic foundation designed to
                  prepare students for career and postsecondary readiness.
                  Additionally, we offer a robust portfolio of focus schools,
                  giving families choice in selecting a program that best fits
                  the needs and interests of their student. With over 70
                  programs to choose from, ranging from aerospace to visual and
                  performing arts, students graduate prepared for success in the
                  complex, globalized world. St. Vrain Valley Schools is moving
                  the needle on academic growth and student success to deliver a
                  strong competitive advantage for all students.{" "}
                </p>
                <a href="https://www.svvsd.org/about/district-overview/by-the-numbers-a-strong-competitive-advantage/">
                  By the Numbers: Student Achievement and Success
                </a>
              </Accordion>
              <Accordion title="Equity in St. Vrain">
                <p>
                  St. Vrain Valley Schools recognizes that well-being and
                  excellence requires a commitment to equity. The district
                  continues to work endlessly to acknowledge and address
                  structures and practices that impact student safety,
                  well-being, learning, and achievement.{" "}
                </p>
                <a href="https://www.svvsd.org/about/equity-in-st-vrain/">
                  Learn more about Equity initiatives and successes in St. Vrain
                </a>
              </Accordion>
              <Accordion title="Open Enrollment Information">
                <p>
                  Choosing a school for your student is an important personal
                  step in charting their future path. Toward this end, St. Vrain
                  offers a diverse and extensive selection of rigorous academic
                  and co-curricular programs that provide you and your student
                  choice in selecting a school that best aligns with their
                  interests and educational goals. Among our many high-quality
                  programs, we are especially proud of the outstanding portfolio
                  of options available across our schools.
                </p>
                <a href="https://www.svvsd.org/schools/enrollment/open-enrollment/">
                  Open Enrollment Information
                </a>{" "}
                |{" "}
                <a href="https://www.svvsd.org/schools/maps-boundaries/">
                  District Maps and Boundaries
                </a>
              </Accordion>
              {/* <Accordion title="Industry-Leading Focus Programs">
                <p>
                  St. Vrain Valley Schools is giving students a strong
                  competitive advantage for success in postsecondary studies and
                  careers through many outstanding focus programs, industry
                  credentials, and real-world learning experiences.
                </p>
              </Accordion> */}
              <Accordion title="District Awards and Recognitions">
                <p>
                  St. Vrain Valley Schools is a nationally recognized center of
                  excellence for advancing student academic growth and
                  opportunity through innovation, technology, and robust
                  community partnerships.
                </p>
                <a href="https://www.svvsd.org/about/district-overview/awards-recognitions/">
                  Recent Awards and Recognitions
                </a>
              </Accordion>
              <Accordion title="Safety and Well-Being">
                <p>
                  Students safety and well-being is paramount in St. Vrain, with
                  over $45 million invested in safety and security upgrades over
                  the past ten years. Additionally, St. Vrain prioritizes
                  student well-being and mental health support, with a robust
                  social emotional (SEL) curriculum at all schools, counseling
                  staff at the elementary schools, investments in additional
                  counseling support at the middle and high school levels, and
                  strong partnerships with local law enforcement, including
                  School Resource Officers stationed at all secondary schools.
                </p>
              </Accordion>
            </div>
          </div>
        </Container>
        <VideoTestimonial
          vimeoId={766967883}
          staticImage={BlueGlobe}
          author={{
            name: "Violet Oliver",
            role: "Niwot High School, Class of 2024",
          }}
        >
          I always feel like there is something new and exciting going on here.
          I feel that we're always on that fringe of what's possible and what's
          impossible. So it is really cool to see across all departments what
          everyone is working on.
        </VideoTestimonial>
 
       
      </section>
      <section id="district-programs" className="pt-24">
        <Container size="lg" className="my-16">
          <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8 max-w-3xl">
            <div className="mb-16">
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                  <i class="fa-duotone fa-university h-5 w-5"></i>
                  </div>
              <h2 className="mt-6">Districtwide Programs</h2>

             
            </div>
          </div>
          <Highlights
          highlights={[
            {
              title: "Innovation Center",
              simpleDescription:
                "Learning at the Innovation Center is different from that in a traditional classroom and provides students with authentic opportunities to apply their skills and knowledge to real-world challenges.",
              imageUrl:
                "https://cdn.sanity.io/images/mx4mn3pt/production/9e30c80891dd0c52157975e00ea7ba4667472986-800x533.jpg?w=640",
              link: "/schools/innovation",
            },
            {
              title: "Career Elevation and Technology Center",
              simpleDescription:
                "CETC offers state-of-the-art career and technical education programs aligned with St. Vrain’s rigorous graduation requirements, as well as postsecondary and workforce readiness competencies.",
              imageUrl:
                "https://cdn.sanity.io/images/mx4mn3pt/production/95ad2e125f13c2d1d72d7d927a72d0f33f4089c1-800x533.jpg?w=640",
                link: '/schools/cetc'
            },
            {
              title:
                "Advanced Global Interactive Learning Environments (AGILE)",
              simpleDescription:
                "The new AGILE telecommunications program allows students to receive synchronous (live) instruction from another high school, expanding learning opportunities for students to curate their education. Contact your school counselors for more information.",
              imageUrl:
                "https://cdn.sanity.io/images/mx4mn3pt/production/b9db659dbe0cb578d90e3db78749b259939bce99-800x533.jpg?w=640",
            },
          ]}
        />
        <Highlights
          highlights={[
            {
              title: "Seal of Biliteracy",
              simpleDescription:
                "The Seal of Biliteracy is an award that will appear on a student’s official transcript to certify that they have demonstrated proficiency in multiple languages.",
              imageUrl:
                "https://cdn.sanity.io/images/mx4mn3pt/production/7646ae7d3d8984f6d0dffeef105a3bdef6967d18-800x534.jpg?w=640",
                href: "https://www.svvsd.org/departments/priority-programs/seal-of-biliteracy/"
            },
            {
              title: "Pathways to Teaching (P-TEACH)",
              simpleDescription:
                "The P-TEACH Program introduces St. Vrain high school students and classified staff to a career as an educator by offering a variety of concurrent enrollment courses.",
              imageUrl:
                "https://cdn.sanity.io/images/mx4mn3pt/production/d5dfbcefff75d47463122e35d4e389700a303225-800x534.jpg?w=640",
                href: "https://innovation.svvsd.org/focus-areas/pathways-to-teaching/",
            },
            {
              title: "Concurrent Enrollment",
              simpleDescription:
                "Available at all high schools, the Concurrent Enrollment program provides an opportunity for all high school students to earn both high school and college credit.",
              imageUrl:
                "https://cdn.sanity.io/images/mx4mn3pt/production/14f6696ed23cf4a993a6a434e18fe27bf0819ef5-800x534.jpg?w=640",
                href: "https://www.svvsd.org/departments/assessment-curriculum-instruction/curriculum/concurrent-enrollment/"
            },
          ]}
        />
          </Container>
          </section>
      <section id="focus-programs" className="pt-24">
        <Container size="lg">
          <FocusPrograms />
        </Container>
        <VideoTestimonial
          vimeoId={287735730}
          author={{
            name: "Natalia Toldeo",
            role: "Silver Creek High School, Class of 2023",
          }}
          staticImage={Grad}
        >
          My education experiences have been amazing because of the support of
          teachers. The classes are challenging, but the teachers really take
          the time to support you so you can succeed inside and outside of
          class.
        </VideoTestimonial>
      </section>
      <section id="schools" className="pt-24">
        <Container size="lg" className="my-16">
          <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base mt-8 max-w-3xl">
            <div className="mb-16">
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[color:var(--bg-color)] text-white">
                  <i class="fa-duotone fa-graduation-cap h-5 w-5"></i>
                  </div>
              <h2 className="mt-6">Your School. Your Choice.</h2>

              <p className="text-xl text-gray-500">
                St. Vrain offers a diverse and extensive selection of rigorous
                academic and co-curricular programs that provide you and your
                student choice in selecting a school that best aligns with their
                interests and educational goals.
              </p>
              <p className="text-gray-500">
                <a href="https://svvsd.org/schools" target="_blank">Contact any school</a> in the district to schedule a tour or learn
                more about program offerings and opportunities for students.
              </p>
            </div>
          </div>
          <ol
            role="list"
            className="-mx-3 grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:text-center xl:-mx-12"
          >
            {allPostsData
              ?.sort((a, b) => a.title.localeCompare(b.title))
              .map((school) => (
                <>
                  {!school.title.includes("Innovation Center") &&
                    !school.title.includes("Career Elevation") &&
                    !school.title.includes("Guide") && (
                      <li
                        key={school.title}
                        className="grid auto-rows-min grid-cols-1 items-center gap-8 px-3 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-1 xl:px-12"
                      >
                        {school.mainImage && (
                          <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                            <div className="absolute inset-0 flex items-center justify-center">
                              <img
                                src={urlFor(school.mainImage).width(640).url()}
                                alt=""
                                unoptimized
                                className="h-full w-full object-cover"
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <h3 className="text-base font-medium tracking-tight text-slate-900">
                            {school.title}
                          </h3>
                          <p className="mt-2 text-sm text-slate-600">
                            <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base">
                              {school.seoDescription && (
                                <>
                                  <p>{school.seoDescription}</p>
                                  <Link to={`/schools/${school.slug.current}`}>
                                    Learn more
                                  </Link>
                                </>
                              )}
                            </div>
                          </p>
                        </div>
                      </li>
                    )}
                </>
              ))}
            <li className="grid auto-rows-min grid-cols-1 items-center gap-8 px-3 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-1 xl:px-12">
              <div className="relative h-48 overflow-hidden rounded-2xl shadow-lg sm:h-60 lg:h-40">
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src={Medical}
                    alt=""
                    unoptimized
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>

              <div>
                <h3 className="text-base font-medium tracking-tight text-slate-900">
                  Innovation & Career Pathways
                </h3>
                <p className="mt-2 text-sm text-slate-600">
                  <div className="prose prose-headings:font-condensed prose-headings:tracking-tight prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-sm sm:prose-base">
                    <>
                      <p>
                        St. Vrain Valley Schools provides numerous pathways for
                        students to engage in rigorous, real-world, and
                        experiential learning in our nation's fasted growing
                        industries.
                      </p>
                      <p>
                        <Link to={`/schools/innovation`}>
                          Innovation Center Programs
                        </Link>
                      </p>
                      <p>
                        <Link to={`/schools/cetc`}>
                          Career Elevation and Technology Center Programs
                        </Link>
                      </p>
                    </>
                  </div>
                </p>
              </div>
            </li>
          </ol>
        </Container>
      </section>
      <Footer />
    </>
  );
}
